<script>
export default {
  name: "SnsButton",
  props: {
    theme: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: "",
    },
    loadingLabel: {
      type: String,
      default: "Loading...",
    },
  },
  emits: ["click"],
  methods: {
    handleClick() {
      if (!this.disabled && !this.loading) {
        this.$emit("click");
      }
    }
  }
}
</script>

<template>
  <button
    class="rounded"
    :class="`btn btn-${theme} ${customClass}`"
    :disabled="disabled || loading"
    @click="handleClick"
  >
    <template v-if="loading">
      <div class="loading loading-spinner" />
      {{ loadingLabel }}
    </template>

    <slot v-else />
  </button>
</template>
