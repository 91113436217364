import globalAxios from "axios";
import store from "../store";
import router from "../router";

const axios = globalAxios.create({
  baseURL: import.meta.env.VITE_APP_ROOT_API,
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;

    const loggedUser = store.getters.getUser;

    if (!error.response) {
      if (!loggedUser) {
        window.location.href = "/login";
        localStorage.clear();
      }

      throw error;
    }

    const nonRedirectRoutes = [
      "login",
      "register",
      "password-recovery",
      "confirm-new-password",
    ];
    const isNonRedirectRoute = nonRedirectRoutes.some(i => router.currentRoute.value.fullPath.includes(i));
    const isTokenExpired = status === 401 && error?.response?.data?.exp === "token expired";

    if ((status === 401 && !isNonRedirectRoute)) {
      if (isTokenExpired) {
        store.dispatch("refreshToken");
      } else {
        store.dispatch("logout");
      }
    }

    throw error;
  }
);

export default axios;
