<script>
import { mapActions } from "vuex";
import SnsModal from "@/components/SnsModal.vue";
import SnsInput from "@/components/SnsInput.vue";
import SnsAlert from "@/components/SnsAlert.vue";
import SnsButton from "@/components/SnsButton.vue";

export default {
  name: "UserChangePasswordModal",
  components: {
    SnsModal,
    SnsInput,
    SnsAlert,
    SnsButton,
  },
  props: {
    isModalOn: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      isLoading: false,
      alert : {
        isVisible: false,
        type: "alert-success text-white",
        header: "Successful!",
        content: "",
      },
    };
  },
  computed: {
    isAllFieldsFilled() {
      return (
        this.currentPassword.length &&
        this.newPassword.length &&
        this.newPasswordConfirm.length
      );
    },
  },
  methods: {
    ...mapActions(["changePassword"]),
    updatePassword() {
      if (this.isAllFieldsFilled) {
        if (this.newPassword === this.newPasswordConfirm) {
          this.alert.isVisible = false;
          this.isLoading = true;

          this.changePassword({
            passwordOld: this.currentPassword,
            passwordNew: this.newPassword,
            passwordConfirm: this.newPasswordConfirm,
          })
            .then(() => {
              this.alert = {
                isVisible: true,
                type: "alert-success text-white",
                header: "Successful!",
                content: "Password changed successfully.",
              };
              this.currentPassword = "";
              this.newPassword = "";
              this.newPasswordConfirm = "";
              this.isLoading = false;
            })
            .catch((error) => {
              console.log(error);
              if(error.response.data.non_field_errors) {
                this.alert = {
                  isVisible: true,
                  type: "alert-error text-white",
                  header: "Error!",
                  content: error.response.data.non_field_errors[0],
                };
              } else {
                this.alert = {
                  isVisible: true,
                  type: "alert-error text-white",
                  header: "Error!",
                  content: error.response.data.detail,
                };
              }

            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          this.alert = {
            isVisible: true,
            type: "alert-warning",
            header: "Warning!",
            content: "New password and confirm new password do not match.",
          };
        }
      } else {
        this.alert = {
          isVisible: true,
          type: "alert-warning",
          header: "Warning!",
          content: "All fields are required.",
        };
      }
    },
    closeModal() {
      this.$emit("close");
    },
  }
}
</script>

<template>
  <SnsModal
    id="user-change-password-modal"
    :is-modal-on="isModalOn"
    @close="closeModal"
  >
    <template #modal-header>
      <div class="font-bold text-xl">
        Change password
      </div>
    </template>

    <template #modal-body>
      <SnsAlert
        v-if="alert.isVisible"
        :type="alert.type"
        :header="alert.header"
        :content="alert.content"
      />

      <div class="flex flex-col space-y-4">
        <div class="basis-full">
          <div class="flex">
            <div class="basis-full">
              <SnsInput
                v-model="currentPassword"
                type="password"
                label="Old password"
                required
              />
            </div>
          </div>

          <div class="flex">
            <div class="basis-1/2 pr-2">
              <SnsInput
                v-model="newPassword"
                type="password"
                label="New password"
                required
              />
            </div>

            <div class="basis-1/2 pl-2">
              <SnsInput
                v-model="newPasswordConfirm"
                type="password"
                label="Confirm new password"
                required
              />
            </div>
          </div>
        </div>
        <div class="flex justify-end">
          <SnsButton
            :disabled="!isAllFieldsFilled || isLoading"
            :loading="isLoading"
            loading-label="Changing password"
            custom-class="disabled:border-accent"
            @click="updatePassword"
          >
            Change password
          </SnsButton>
        </div>
      </div>
    </template>
  </SnsModal>
</template>
