import { createApp } from "vue";
import App from "./App";
import store from "./store";
import router from "./router";
import Axios from "../src/plugins/axios";
import Notifications from "@kyvg/vue3-notification";
import initSentry from "@/plugins/sentry.js";

import "bootstrap-icons/font/bootstrap-icons.css";
import "@vuepic/vue-datepicker/dist/main.css";
import "@/plugins/veeValidate.js";
import "@/styles/main.scss";

const app = createApp(App);

if (import.meta.env.VITE_APP_IS_ONPREM === "off") {
  initSentry(app, router);
}

app.use(Notifications);

// app.provide("$http", Axios);
app.config.globalProperties.$http = Axios;

const token = localStorage.getItem("token");

if (token) {
  app.config.globalProperties.$http.defaults.headers.common.Authorization = `Bearer ${token}`;
}

app.use(router);
app.use(store);

app.mount("#app");
