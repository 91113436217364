<script>
import SnsLogo from "@/components/SnsLogo.vue";

export default {
  name: "VerificationLayout",
  components: {
    SnsLogo,
  },
};
</script>

<template>
  <div class="verification-layout">
    <SnsLogo image-class="h-10 w-auto" />
    <div class="verification-layout-container">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss">
.verification-layout {
  @apply bg-base-100;
  padding: 30px;
}
.verification-layout-container {
  width: 500px;
  max-width: 500px;
  min-width: 500px;
  padding: 20px;
  margin: 20px auto 0;
  border-radius: 4px;
}
</style>
