import { defineRule, configure } from "vee-validate";
import { required, email, numeric } from "@vee-validate/rules";
import { localize } from "@vee-validate/i18n";
import { publicEmailProviderList } from "@/constants/publicEmailProviderList.js";

defineRule("required", required);
defineRule("email", email);
defineRule("numeric", numeric);

defineRule("workEmail", (value) => {
  if (!publicEmailProviderList
      .some((e) => value.toLowerCase().includes(`@${e.toLowerCase()}`)) && email(value)
  ) {
    return true;
  }
  return "Please provide a valid work e-mail address.";
});

defineRule("website", (value) => {
  const rgx =
    new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
      "i"
    );

  const isValid = rgx.test(value);

  if (isValid) {
    return true;
  }
  return "The website must be valid";
});

defineRule("url", (value) => {
   const rgx = new RegExp(
    "^(https?:\\/\\/|ftp:\\/\\/|www\\.)?" + // protocol or www
    "(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,63}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3})|" + // IPv4
    "\\[([a-fA-F\\d:]+)\\]" + // IPv6
    "(\\:\\d{1,5})?" + // port (optional)
    "(\\/[\\w\\-.~%]*)*" + // path (optional)
    "(\\?[;&\\w\\-.~+=%]*)?" + // query string (optional)
    "(\\#[\\w\\-.~+=%]*)?$", // fragment (optional)
    "i"
  );

  const isValid = rgx.test(value);

  if (isValid) {
    return true;
  }
  return "Invalid URL";
});

configure({
  // Generates an English message locale generator
  generateMessage: localize("en", {
    messages: {
      required: "This field is required.",
      email: "The E-mail field must be a valid email",
      numeric: "This field may only contain numeric characters",
    },
  }),
});
